import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/10D/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/10D/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/10D/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/10D/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/10D/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/10D/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/10D/7.jpg';
// import p8 from 'assests/Photos/ClassAssembly/2024/10D/8.jpg';




// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly10D2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/10D/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/10D/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/10D/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/10D/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/10D/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/10D/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/10D/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/class-assembly/10D/8.webp`;
 
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source:p6,
        rows: 1,
        cols: 1,
      },
     
      {
        src: p7,
        source: p7,
        rows: 1.8,
        cols: 2,
      },
      {
          src: p8,
          source: p8,
          rows: 1.8,
          cols: 2,
        },
      

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Social Advertisement-Sustainable Development	
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 10D Date: 4, 5 & 6 July 2024

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 10 D presented their class assembly on the topic SUSTAINABLE DEVELOPMENT THROUGH SOCIAL ADVERTISEMENT. On 4 July 2024, students introduced their assembly topic along with a song and dance performance. The song lyrics highlighted themes of conservation, recycling, and the beauty of nature, while the choreography incorporated energetic movements symbolizing unity and collective action towards sustainable goals
        <br></br>
        Students enacted a skit next day portraying a scenario where a community faced resource depletion. It depicted how individuals, initially unaware of their impact on the environment, learned about sustainable practices through social advertisements. Followed by an interactive quiz next day which focused on social advertisements promoting sustainable development. 
        <br></br>
        The quiz was designed to engage students in a fun and educational way, testing their knowledge and raising awareness about the role of social advertisements in promoting sustainability. Overall, the assembly served as a meaningful platform to educate and inspire students to become active participants in promoting sustainable development through awareness and action.
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “The most powerful element in advertisement is the truth”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10D2024;